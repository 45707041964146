import "core-js/modules/es.array.push.js";
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "register-agent-body"
};
import Footer from '@/view/box/components/footer.vue';
import axios from 'axios';
import { showFailToast, showSuccessToast } from 'vant';
import { authorization } from '../../../public/static/js/headers';
import { useRouter } from 'vue-router';
export default {
  __name: 'registerAgent',
  setup(__props) {
    const router = useRouter();
    const buyAgent = () => {
      axios.post('/api/appOrder/createAgentOrder', {}, authorization()).then(res => {
        if (res.data.success) {
          const data = res.data.data;
          onBridgeReady(data);
        } else {
          showFailToast(res.data.msg);
        }
      }).catch(err => {
        showFailToast(err.message);
      });
    };
    function onBridgeReady(data) {
      const payData = data.pay;
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        'appId': payData.appId,
        //公众号ID，由商户传入
        'timeStamp': payData.timeStamp,
        //时间戳，自1970年以来的秒数
        'nonceStr': payData.nonceStr,
        //随机串
        'package': payData.packageVal,
        'signType': payData.signType,
        //微信签名方式：
        'paySign': payData.paySign //微信签名
      }, async function (res) {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          showSuccessToast('支付成功!');
        } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
          showFailToast('取消支付!');
        } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
          showFailToast('支付失败!');
        } else {
          showFailToast('调起支付失败!');
        }
        await axios.post('/api/appOrder/queryAgentOrder', {
          'orderNo': data.orderNo
        }, authorization()).then(async res => {
          if (res.data.data === 'SUCCESS') {
            await router.push({
              path: '/my'
            });
          }
        });
      });
    }
    if (typeof WeixinJSBridge == 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
      }
    }
    return (_ctx, _cache) => {
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_button, {
        type: "primary",
        round: "",
        size: "large",
        class: "register-agent-button",
        onClick: buyAgent
      }, {
        default: _withCtx(() => [_createTextVNode("成为代理 ")]),
        _: 1
      }), _createVNode(Footer)])]);
    };
  }
};